<template>
  <!--
    A form to edit the flowAreas of the videoDirectorConfigs.
  -->
  <div class="flowEditor">
    <template v-if="laneConfig && flowArea">
      <div class="row">
        <div class="col-12">
          <!-- VIDEO DIRECTOR CONFIG DEFAULT VISUALISER COMPONENT START -->
          <VideoDirectorConfigDefaultVisualiser
            ref="videoDirectorConfigDefaultVisualiser"
            :selected-flow-area-id="flowArea.id"
            :lane-config="laneConfig"
            :edit-flow="true"
            :display-all-axes="true"
            :zoom-to-border="true"
            @changePtz="changePtz"
          />
          <!-- VIDEO DIRECTOR CONFIG DEFAULT VISUALISER COMPONENT END -->
        </div>
        <div class="col-12 pt-4">
          <ul class="nav nav-tabs">
            <li
              v-for="(tab, index) in tabs"
              :key="index"
              class="nav-item"
            >
              <a
                :class="['nav-link', activeTab === tab ? 'active' : '']"
                @click="changeTab(tab)"
              >{{ $t(`flowEditor.tab.${ tab }`) }}</a>
            </li>
          </ul>

          <FlowEditorCommon
            v-if="activeTab === 'common'"
            :flow-area="flowArea"
          />

          <FlowEditorInitArea
            v-if="activeTab === 'initArea'"
            :flow-area="flowArea"
            @changePtz="changePtz"
            @update="update()"
          />

          <FlowEditorReleaseArea
            v-if="activeTab === 'releaseArea'"
            :flow-area="flowArea"
            @update="update()"
          />

          <FlowEditorTurnOffArea
            v-if="activeTab === 'turnOffArea'"
            :flow-area="flowArea"
            @update="update()"
          />
        </div>
      </div>

      <hr>

      <div class="text-right">
        <button
          class="btn btn-sm btn-primary"
          @click="close()"
        >
          {{ $t('close') }}
        </button>
      </div>
    </template>

    <template v-else>
      {{ $t('noDataAvailable') }}
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: 'FlowEditor',
  components: {
    VideoDirectorConfigDefaultVisualiser: () => import('../Visualiser/VideoDirectorConfigDefaultVisualiser.vue'),
    FlowEditorCommon: () => import('./FlowEditorCommon.vue'),
    FlowEditorInitArea: () => import('./FlowEditorInitArea.vue'),
    FlowEditorReleaseArea: () => import('./FlowEditorReleaseArea.vue'),
    FlowEditorTurnOffArea: () => import('./FlowEditorTurnOffArea.vue')
  },
  mixins: [
    errorMixin
  ],
  props: {
    flowArea: {
      type: Object,
      required: true
    },
    laneConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tabs: [ 'common', 'initArea', 'releaseArea', 'turnOffArea' ],
      activeTab: 'common',
    }
  },
  created () {
    if (!this.flowArea.releaseArea) {
      this.flowArea.releaseArea = [];
    }
    if (!this.flowArea.turnOffArea) {
      this.flowArea.turnOffArea = [];
    }
    if (!this.flowArea.initArea) {
      this.flowArea.initArea = {
        catchTimeout: 0,
        motionDetectionActive: true,
        motionDetectionArea: [],
        motionDetectionObjectConfig: {
          minimumWidth: 0,
          minimumHeight: 0,
          minimumLifetime: 0,
          detectionDelay: 0,
          snowSuppresion: false,
          shadowSuppresion: false,
          requireUprightCandidates: false,
          lowConfidenceThreshold: null
        },
        position: {
          pan: 0,
          tilt: 0
        }
      }
    }
  },
  methods: {
    changePtz (position) {
      this.$emit('changePtz', position);
    },
    close () {
      this.$emit('close');
    },
    update () {
      this.$refs.videoDirectorConfigDefaultVisualiser.update();
    },
    changeTab (tabName) {
      this.activeTab = tabName;
      if (tabName === 'initArea') {
        this.enableMotionDetection();
      } else {
        this.disableMotionDetection();
      }
    },
    enableMotionDetection () {
      this.$emit('showMotionDetectionArea', true);
    },
    disableMotionDetection () {
      this.$emit('showMotionDetectionArea', false);
    }
  }
}
</script>

<style scoped>
.nav-item {
  cursor: pointer;
}
.boolLabel {
  position: relative;
  top: -1.5rem;
}
.dial {
  width: 33%;
  float: left;
  height: 33%;
}
</style>
